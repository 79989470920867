import { _axios } from '@/plugins/axios'

export const apiApplicationList = (dataJson, method = 'get') => {
	return _axios({
		// url: `/car/application/list`,
		url: `/car/application/searchPage`,
		method: method,
		data: dataJson
	})
}

export const apiInvalidApplication = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/application/deleteApplication`,
		method: method,
		data: dataJson
	})
}
export const apiRecoveryApplication = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/application/recoveryApplication`,
		method: method,
		data: dataJson
	})
}
export const apiApplicationFeedback = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/application/feedback`,
		method: method,
		data: dataJson
	})
}
export const apiAssignSalesMan = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/application/assignSalesMan`,
		method: method,
		data: dataJson
	})
}

export const apiHistoryList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/application/historyList`,
		method: method,
		data: dataJson
	})
}

// 批量取消分配业务员
export const apiUnassignSalesMan = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/application/unassignSalesMan`,
		method: method,
		data: dataJson
	})
}

// 获取一条线索下的通话记录
export const apiContact = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/application/contact`,
		method: method,
		data: dataJson
	})
}

// 转化为意向客户
export const apiCastIntention = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/application/castIntention`,
		method: method,
		data: dataJson
	})
}

// 转化为申请客户
export const apiCastToApplyCustomer = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/web/preApplication/castToApplyCustomer`,
		method: method,
		data: dataJson
	})
}

// 线索客户
export const apiLeadsPage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/client/leads/page`,
		method: method,
		data: dataJson
	})
}

// 意向客户
export const apiPotentialPage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/client/potential/page`,
		method: method,
		data: dataJson
	})
}

// 获取跟进人列表
export const apiMarketersAndSales = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/marketer/marketersAndSales`,
		method: method,
		data: dataJson
	})
}

// 分配线索跟进人
export const apiLeadsAssign = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/client/leads/batch/assign`,
		method: method,
		data: dataJson
	})
}

// 分配意向跟进人
export const apiPotentialAssign = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/client/potential/batch/assign`,
		method: method,
		data: dataJson
	})
}

// 导出线索数据
export const apiLeadsData = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/client/leads/data`,
		method: method,
		data: dataJson
	})
}

// 导出意向数据
export const apiPotentialData = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/client/potential/data`,
		method: method,
		data: dataJson
	})
}
