<template>
	<div>
		<search-list-table
			ref="searchListTable"
			:columns="columns"
			:formSearchList="formSearchList"
			:initDataSource="initDataSource"
			:upfrontFeesRowSelection="leadStatus === '0' ? upfrontFeesRowSelection : null"
		>
			<template slot="toggleTable">
				<a-tabs :activeKey="leadStatus" @change="changeTab">
					<a-tab-pane key="0" :tab="$t('table.CustomerManagement.Intention.inProgress')"> </a-tab-pane>
					<a-tab-pane key="1" :tab="$t('table.CustomerManagement.Intention.converted')" force-render> </a-tab-pane>
					<!-- <a-tab-pane key="2" :tab="$t('table.CustomerManagement.Intention.invaidated')" force-render> </a-tab-pane> -->
					<div slot="tabBarExtraContent">
						<a-button v-if="leadStatus === '0'" type="primary" :disabled="upfrontFeesSelectRow.length == 0" @click="showAssignModal()">{{
							$t('page.assignSalesman')
						}}</a-button>
						<a-button type="primary" style="margin-left: 20px" @click="exportSheet">
							{{ $t('page.download') }}
						</a-button>
					</div>
				</a-tabs>
			</template>
		</search-list-table>
		<a-modal v-model="assignModalShow" title="Assign" :destroyOnClose="true" width="600px" @ok="assignFollowCollector">
			<a-form-item label="Choose a Sales" class="send">
				<a-select v-model="followerNameSelect" mode="multiple">
					<a-select-option :value="item.id" v-for="item in marketersList" :key="item.id">{{ item.displayName }}</a-select-option>
				</a-select>
			</a-form-item>
		</a-modal>
	</div>
</template>
<script>
import moment from 'moment'
import { apiLeadsPage, apiMarketersAndSales, apiLeadsAssign, apiLeadsData } from '@/api/application'
export default {
	data() {
		return {
			upfrontFeesSelectRow: [],
			upfrontFeesRowSelection: {
				fixed: true,
				columnWidth: 50,
				selectedRowKeys: [],
				onChange: (k, r) => {
					this.upfrontFeesRowSelection.selectedRowKeys = k
					this.upfrontFeesSelectRow = r
				}
			},
			assignModalShow: false,
			followerNameSelect: [],
			marketersList: [],
			leadStatus: '0'
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.CustomerManagement.display_name',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.phone_number',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.CustomerManagement.salesman_username',
					type: 'select',
					validateField: ['follower'],
					selectOption: this.marketersList,
					dataPreType: {
						value: 'id',
						text: 'displayName'
					}
				},
				{
					label: 'table.CustomerManagement.intention_status',
					type: 'select',
					validateField: ['feedback'],
					selectOption: ['potential', 'waiting'],
					prefix: 'table.CustomerManagement.intention_status_map'
				},
				{
					label: 'table.CustomerManagement.create_time',
					type: 'range-picker',
					validateField: ['submitTime', { initialValue: [moment(new Date()).subtract(1, 'weeks'), moment()] }],
					paramsName: ['submitTimeBegin', 'submitTimeEnd']
				},
				{
					label: 'table.CustomerManagement.last_feedback_time',
					type: 'range-picker',
					validateField: ['lastContactTime', { initialValue: [] }],
					paramsName: ['lastContactTimeBegin', 'lastContactTimeEnd']
				}
			]
		},
		columns() {
			const columnsList = [
				{
					title: this.$t('table.CustomerManagement.create_time'),
					dataIndex: 'createTime',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.display_name'),
					dataIndex: 'name',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.IntentionDetail.gender'),
					dataIndex: 'sex',
					align: 'center',
					customRender: (v, o) => {
						return v ? this.$t(`table.CustomerManagement.IntentionDetail.gender_map.${v === 1 ? '0' : '1'}`) : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.phone_number'),
					dataIndex: 'mobile',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.Permission.User.email'),
					dataIndex: 'email',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.EmployeeManagement.Branchman.branchOffice'),
					dataIndex: 'officeName',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.intention_status'),
					dataIndex: 'feedback',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.last_feedback_time'),
					dataIndex: 'lastContactTime',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.invalidRemark'),
					dataIndex: 'remark',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: 'Follower',
					dataIndex: 'followerName',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				}
			]
			return this.leadStatus === '0' ? columnsList : columnsList.filter((item) => item.dataIndex !== 'followerName')
		}
	},
	created() {
		this.$nextTick(() => {
			apiMarketersAndSales().then((result) => {
				this.marketersList = result
			})
		})
	},
	methods: {
		initDataSource(initParams) {
			return apiLeadsPage({ ...initParams, leadStatus: this.leadStatus })
		},
		changeTab(key) {
			this.leadStatus = key
			this.$refs.searchListTable.initTable(1)
		},
		showAssignModal() {
			if (this.upfrontFeesSelectRow.length === 0) {
				this.$message.error('Please choose cases first')
				return false
			}
			this.followerNameSelect = []
			this.assignModalShow = true
		},
		assignFollowCollector() {
			if (this.followerNameSelect.length === 0) {
				this.$message.error('Please choose a Sales')
				return false
			}
			apiLeadsAssign({
				leadIds: this.upfrontFeesSelectRow.map((item) => item.id), //线索id
				userIds: this.followerNameSelect //人员id
			}).then(() => {
				this.$message.success('Success')
				this.assignModalShow = false
				this.upfrontFeesRowSelection.selectedRowKeys = []
				this.upfrontFeesSelectRow = []
				this.followerNameSelect = []
				this.$refs.searchListTable.initTable(1)
			})
		},
		exportSheet() {
			const searchForm = { ...this.$refs.searchListTable.searchFormTable.getFieldsValue() }
			searchForm.submitTimeBegin = searchForm.submitTime[0] && searchForm.submitTime[0].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.submitTimeEnd = searchForm.submitTime[1] && searchForm.submitTime[1].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.lastContactTimeBegin = searchForm.lastContactTime[0] && searchForm.lastContactTime[0].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.lastContactTimeEnd = searchForm.lastContactTime[1] && searchForm.lastContactTime[1].format('YYYY-MM-DD') + ' 00:00:00'
			delete searchForm.submitTime
			delete searchForm.lastContactTime
			apiLeadsData(searchForm).then((res) => {
				let str = `Name,Gender,Mobile,Branch Office,Follower,Email,Client Intentions,Last Contact Time,Submit Time,Remarks`
				res.forEach((item) => {
					str += `\r\n${item.name},${this.$t(`table.CustomerManagement.IntentionDetail.gender_map.${item.sex}`)},${item.mobile},${item.officeName},${
						item.followerName
					},${item.email},${item.feedback},${item.lastContactTime},${item.createTime},${item.remark}`
				})
				//encodeURIComponent解决中文乱码
				let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
				let link = document.createElement('a')
				link.href = uri
				link.download = `Client Leads.csv`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		}
	}
}
</script>

<style scoped lang="less">
.send {
	display: flex;
	margin-top: 20px;
	.ant-form-item-label {
		font-weight: 600;
	}
	.ant-input,
	.ant-select {
		width: 360px;
		height: 40px;
		line-height: 40px;
	}
}
</style>
